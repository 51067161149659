// 问卷列表
const getTopicListURL = `/gateway/hc-govern/miniapi/qu/topicList`;
// 查询话题详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
// 点赞
const addPraiseURL = `/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `/gateway/hc-neighbour/topic/cancelPraise`;
// 进行评论
const addCommentURL = "/gateway/hc-neighbour/topic/addComment";
// 话题评论列表分页查询
const getTopicCommentList = "/gateway/hc-neighbour/topic/getTopicCommentList";
// 评论回复列表分页查询
const getTopicReplyList = "/gateway/hc-neighbour/topic/getTopicReplyList";

// 用户信息接口
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
// 查询产权人认证审核状态
const getUserHouseOwnerAuditStatusUrl = `/gateway/blade-user/user/getUserHouseOwnerAuditStatus`;
// 产权人身份认证信息提交
const applyHouseOwnerAuditUrl = `/gateway/blade-user/user/applyHouseOwnerAudit`;
// 查询用户提交过的真实姓名
const getProvidedRealNameUrl = `/gateway/blade-user/user/getProvidedRealName`;
// 上传签名
const uploadURL = `/gateway/blade-resource/oss/endpoint/put-file`;
//运营位列表
const getBannerListUrl = `/gateway/hc-neighbour/topic/getTopicList`;
export {
  getBannerListUrl,
  getTopicListURL,
  getTopicInfoByIdURL,
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
  userInfoUrl,
  getUserHouseOwnerAuditStatusUrl,
  applyHouseOwnerAuditUrl,
  getProvidedRealNameUrl,
  uploadURL,
};
